<template>
  <v-footer
    id="dashboard-core-footer"
    elevation="12"
    color="white"
  >
    <div class="grid grid-template-columns">
      <div />
      <v-container class="text-center">
        <a
          href="http://lactalis.com.br/pt/"
          target="_blank"
        >
          <span style="color: #005dbc">
            &copy;{{ new Date().getFullYear() }} — <strong style="font-family: Poppins, sans-serif; font-size: 1rem">Lactalis do Brasil</strong> —
          </span>
          <span
            style="color: #005dbc; font-family: Poppins, sans-serif; font-size: 0.8rem"
          >
            &nbsp;{{ version }}
          </span>
        </a>
        <div
          style="font-size: 12px;"
          class="mt-2 text-center pb-0"
        >
          <a
            :href="''"
            class="footer--text my-1"
            style="text-decoration:none;"
          >
            <v-icon
              small
              color=""
            >
              mdi-file-account-outline
            </v-icon>
            Termo de uso / Adesão
          </a>
          <a
            href="/termos/politica_privacidade.html"
            target="_blank"
            class="footer--text my-1"
            style="text-decoration:none;"
          >
            <v-icon
              small
              color=""
            >
              mdi-file-lock-outline
            </v-icon>
            Política de privacidade
          </a>
        </div>
      </v-container>
      <div style="display: flex; align-items: center; justify-content: end;">
        <a
          href="https://esteiogestao.com.br/"
          target="_blank"
        >
          <img
            style="max-height: 160px; max-width: 160px;"
            src="@/assets/esteio/powered_by_horizontal_c.png"
          >
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script>
  import packageJson from '../../../package.json'
  export default {
    name: 'DashboardCoreFooter',

    data () {
      return {
        version: this.getVersion(),
      }
    },
    methods: {
      getVersion () {
        const versionApi = localStorage.getItem('version_api')
        return 'versão: ' + (
          versionApi
            ? (versionApi + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + (packageJson.version !== versionApi ? '*' : ''))
            : packageJson.version + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + '*'
        )
      },
    },
  }
</script>
<style>
  .grid {
    display: grid;
    width: 100%;
  }

  .grid-template-columns {
    grid-template-columns: 1fr 2fr 1fr;
  }
</style>
